.Navbar{
    background: transparent!important;
    padding: 0!important;
    width: 100vw;
}
.nav-logo{
    position: relative;
}
.na-logo{
    display: flex;
    justify-content: center;
}
.na-logo img{
    transform: translateY(.75rem);
    width: 75%;
    height: auto;
}
.Navbg{
    width: 100vw;
    position: relative;
    transform: translate(0, -30%);
}
.nav-absolute{
    transform: translateY(-40%);
    width: 87vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 5%;
    top: 25%;
}
.na-menu a{
    text-decoration: none;
    color: #4F7B90;
}

.active{
    color: blue !important;
    font-weight: 550;
}
@media only screen and (max-width: 1850px) {
    .nav-absolute{
        transform: translateY(-50%);
    }
}
@media only screen and (max-width: 992px) {
    .nav-absolute{
        width: 95vw;
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: absolute;
        left: 0%;
        top: 30%;
    }
    .Navbg{
        position: relative;
        height: 80%;
        transform: translate(-0%, -15%);
    }
    .na-menu{
        margin-top: 2rem;
    }
}
@media only screen and (max-width: 768px) {
    .na-logo img{
        width: 60%;
    }
    .Navbg{
        transform: translate(-0%, -7.25%);
    }
    .na-menu {
        margin-top: 1.5rem;
    }
    .na-logo img{
        transform: translate(-5%, 12.5%);
    }
}



.custom-alert {
position: absolute;
top: 5%;
width: 50%;
left: 25%;
right: 25%;
text-align: center;
background-color: #f44336; /* Red background color */
color: white; /* Text color */
padding: 15px; /* Padding around content */
margin-bottom: 15px; /* Bottom margin */
border-radius: 4px; /* Rounded corners */
}

.custom-alert button {
background-color: #4caf50; /* Green background color for the button */
color: white; /* Text color for the button */
border: none; /* No border for the button */
padding: 10px 15px; /* Padding for the button */
margin-top: 10px; /* Top margin for the button */
cursor: pointer; /* Add cursor pointer on hover */
border-radius: 4px; /* Rounded corners for the button */
}

.custom-alert button:hover {
background-color: #45a049; 
}