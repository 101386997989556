.ribbon-label {
    position: relative;
    background-color: red; /* Change the background color as desired */
    color: #fff; /* Text color */
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}

.button-close-maps{
  border: none;
  background: none;
  position: absolute;
  right: -17.5%;
  top: -7.5%;
}
.button-close-maps Icon{
  width: 5rem;
  height: 5rem;
}

/* Arrow-down element */
.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid red; /* Match the background color of the ribbon-label */
    position: absolute;
    bottom: -10px; /* Adjust this value to control the distance between the ribbon and the arrow */
    left: 50%;
    transform: translateX(-50%);
}
.svg-main path{
    fill: #9CB9C7 !important;
}

.svg-container {
    max-width: your-max-width-value;
    width: 100%;
  }
  
  .svg-container svg {
    max-width: 100%;
    height: auto;
  }
  
  .svg-container path {
    max-width: 100%;
  }
  