.apexcharts-legend{
    display: none !important;
}
.apexcharts-legend-marker{
    border-radius: 50% !important;
}
.chart-sosial-media{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.chart-title{
    display: flex;
    align-items: center;
}
.oval{
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.content-chart-title .ig{
    background-color: #CC00FF;
}
.content-chart-title .tt{
    background-color: #000000;
}
.content-chart-title .fb{
    background-color: #1877F2;
}
.content-chart-title .x{
    background-color: #00FFA3;
}
.content-chart-title{
    display: flex;
    align-items: center;
}
.content-csm{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.parent-teknologi{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-parent-teknologi{
    width: 90vw;
    margin-top: 10%;
}

.list-sosmed{
    align-items: center;
}
.total-chart .cc{
    transform: translateY(-.5rem);
}
.dropdown-sosmed{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .5rem;
    border-radius: 5px !important;
}
/* .dropdown-menu-sosmed{
    position: absolute !important;
    margin-left: 0 !important;
    width: 90vw;
    height: 50vh;
    left: 0 !important;
    overflow-x: hidden;
} */
.dropdown-menu-sosmed {
    position: absolute !important;
    margin-left: auto !important;
    margin-right: auto !important; /* Tambahkan ini untuk membuat dropdown berada di tengah */
    /* left: 0 !important; */
    /* right: 0 !important; */
    width: 90vw !important;
    height: 50vh !important;
    top: 50% !important;
    /* transform: translateY(-50%) !important; */
    overflow-x: hidden !important;
  }

.centered-dropdown-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

.list-daerah-dropdown{
    /* margin-top: -20rem !important; */
}


.cobadah path{
    fill: #00D6BC;
}
.svgsvgsvg{
    margin: auto;
    height: max-content !important;
}
.ssssssssss{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

