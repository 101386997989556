@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: 'Poppins', sans-serif;
}

h1{
  margin-bottom: 0 !important;
  font-size: calc(60% + 4vw) !important;
}
h2{
  margin-bottom: 0 !important;
  font-size: calc(60% + 3vw) !important;
}
h3{
  margin-bottom: 0 !important;
  font-size: calc(60% + 2vw) !important;
}
h4{
  margin-bottom: 0 !important;
  font-size: calc(60% + 1vw) !important;
}
h5{
  margin-bottom: 0 !important;
  font-size: calc(60% + 1vw) !important;
}
h6{
  margin-bottom: 0 !important;
}
p{
  margin-bottom: 0 !important;
  font-size: calc(60% + .5vw) !important;
}
p.navbar{
  margin-bottom: 0 !important;
  font-size: calc(60% + 0.5vw) !important;
}
p.ict{
  margin-bottom: 0 !important;
  /* font-size: calc(60% + 0.5vw) !important; */
  font-size: 1.25vw !important;
}

.fw-400{
  font-weight: 400 !important;
}
.fwb{
  font-weight: bold !important;
}
.lh0{
  line-height: .75;
}
.lh5{
  line-height: .5;
}


.cb{
  color: #0166FE !important;
}
.cc{
  color: #00D6BC !important;
}
.cr{
  color: #92328E !important;
}
.cj{
  color: #5D9DBC !important;
}