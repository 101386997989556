/* ----------------------------- GRID LIST UMKM ----------------------------- */

.grid-list-umkm{
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-template-areas: 
    "totalUmkm sektorUsaha penghasilan"
    "skalaUsaha sektorUsaha penghasilan"
    "skalaUsaha adopsiTeknologi levelUmkm"
    "skalaUsaha adopsiTeknologi levelUmkm";
    gap: 1rem;
  }
  .totalUmkm{ grid-area: totalUmkm; }
  .skalaUsaha{ grid-area: skalaUsaha; }
  .sektorUsaha{ grid-area: sektorUsaha; }
  .penghasilan{ grid-area: penghasilan; }
  .adopsiTeknologi{ grid-area: adopsiTeknologi; }
  .levelUmkm{ grid-area: levelUmkm; }



.circle-graph img{
    width: 100%;
}
.circle-graph-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.lu-content-umkm{
    height: 100%;
    justify-content: space-around;
}
.content-level-umkm{
    height: 100%;
}
.lc-content{
    height: 100%;
}

.vr-umkm {
    display: inline-block;
    align-self: stretch;
    width: var(--bs-border-width);
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25;
}

.cobain{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: block;
    margin: auto;
    transform: translate(0, 0);
}
.cobadah{
    /* transform: translate(50%, 50%);
    transform-origin: center center; */
    transform: translate(0, 0);
}


/* ICON SELECT */
.custom-select {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc !important;
    border-radius: 7.5px;
}

.custom-select select {
    display: inline-block;
    padding: 8px 30px 8px 10px; /* Sesuaikan dengan kebutuhan Anda */
    border: 1px solid #ccc !important;
    border-radius: 7.5px !important;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

.custom-select .arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease; /* Efek transisi untuk perubahan rotasi */
}

.custom-select select:focus + .arrow {
    transform: translateY(-50%) rotate(210deg);
}

.custom-select select:not(:focus) + .arrow {
    transition: transform 0.3s ease;
    transform: translateY(-50%) rotate(0deg);
}

#arrowPath {
    fill: #555; /* Sesuaikan dengan warna yang Anda inginkan */
}



/* -------------------------------------------------------------------------- */
/*                                 RESPONSIVE                                 */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
    .circle-graph img{
        width: 50%;
    }
    .lu-content-umkm{
        height: max-content;
    }
}
@media only screen and (max-width: 992px) {
    .sektor-usaha-list p{
        width: 75% !important;
    }
    .sektor-usaha-list .LineSektor{
        width: 65% !important;
    }
}