.pageProfile{
    height: fit-content;
}
.btn-danger{
    font-size: calc(50% + 1vmin);
}
.banner{
    background-color: #9CB9C7;
}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Profile1{
    height: auto;
    width: 100%;
    object-fit: contain;
}
#content{
    transform: translateY(-45%);
}
footer{
    margin-top: -10rem;
}
.vr-profile {
    margin-top: 0;
    display: inline-block;
    margin-left: 1%;
    align-self: center;
    width: var(--bs-border-width);
    height: 1.5rem !important;
    background-color: currentcolor;
    opacity: 1;
}

@media (min-width: 0px) { 
    .banner{
        width: 100%;
        height: 20rem;
    }
 }
/* @media (min-width: 768px) { 
    .banner{
        width: 100%;
        height: 20rem;
    }
} */
@media (min-width: 992px) { 
    .banner{
    width: 100%;
    height: 30rem;
} }

@media (max-width: 768px){
    #content{
        transform: translateY(-27.5%);
    }
}
@media (min-width: 768px) and (max-width: 992px){
    h1{
        font-size: calc(75% + 2vmin);
    }
    p{
        font-size: calc(40% + 1vmin);
    }
}