.parent{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-parent{
    width: 90vw;
    height: 88vh;
    margin-top: 8%;
}
.content-left{
  height: 80vh;
  display: flex;
  flex-direction: column;
}
.content-right{
  height: 80vh;
  display: flex;
  flex-direction: column;
}
#total-umkm{
  height: max-content !important;
}

.svg-maps{
  height: max-content !important;
}
.flex-at{
  flex: 1;
}

.hr {
    border-top: 2px solid #acaaaa;
    margin: 5px 0;
  }

.hr-new {
    border-top: 2px solid #acaaaa;
    margin: 10px 0;
  }

  .vr {
    margin-top: 1%;
    display: inline-block;
    margin-left: 1%;
    align-self: center;
    width: var(--bs-border-width);
    height: 4em !important;
    background-color: currentcolor;
    opacity: .25;
}

  .totalBorder {
    border: .2px solid #e1e1e6  ;
    position: relative;
    padding: 5px;
    width: 100%;
  }
  .top-border,
  .bottom-border {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 1rem;
    height: 1rem;
    left: -1px;
  }
  .top-border {
    width: calc(100% + 4px);
    top: -2px;
  }
  .bottom-border {
    bottom: -2px;
    width: calc(100% + 4px);
  }
  .top-border:after,
  .top-border:before,
  .bottom-border:after,
  .bottom-border:before {
    content: '';
    width: 1rem;
    height: 1rem;
  }
  .top-border:after,
  .top-border:before {
    border-top: 2px solid #0A354A;
  }
  .bottom-border:after,
  .bottom-border:before {
    border-bottom: 2px solid #0A354A;
  }
  .top-border:before,
  .bottom-border:before {
    border-left: 2px solid #0A354A;
  }
  .top-border:after,
  .bottom-border:after {
    border-right: 2px solid #0A354A;
  }

/* #adopsi-teknologi{
  height: 100%;
}
#level-umkm{
  height: 100%;
}
#skala-usaha{
  height: 100%;
} */
  
/* ----- CONTENT TOTAL UMKM -----*/
.content-man{
  align-items: center;
  line-height: 1;
}
.content-man h4{
  font-weight: bold;
}
.content-woman{
  align-items: center;
  line-height: 1;
}
.content-man-icon h1{
  display: flex;
  align-items: center;
}

/* ----- CONTENT SKALA USAHA ----- */
.content-su-top{
    position: relative;
    height: 100%;
}
.content-for-absolute{
    height: 100%;
    text-align: center;
    position: relative;
}
.Topleft{
  width: 2vw;
    position: absolute;
    top: 0;
    left: 0;
}
.Topright{
  width: 2vw;
    position: absolute;
    top: 0;
    right: 0;
}
.Bottomleft{
  width: 2vw;
    position: absolute;
    bottom: 0;
    left: 0;
}
.Bottomright{
  width: 2vw;
    position: absolute;
    bottom: 0;
    right: 0;
}
.img-chart-text {
  position: absolute;
  color: white;
  width: 100%;
}
.MainChart{
  width: 100%;
  height: 100%;
  max-width: 550px;
  max-height: 550px;
}
.ict-1{
  top: 5.25%;
}
.ict-2{
  top: 23%;
}
.ict-3{
  top: 44%;
}
.ict-4{
  top: 70.5%;
}
.hr-skala-usaha{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.content-su-bottom{
    align-items: center;
}
.vr-skala-usaha{
    margin: 0 1rem;
    display: inline-block;
    align-self: center;
    width: var(--bs-border-width);
    height: 2.5em !important;
    background-color: currentcolor;
    opacity: .25;
}
.su-list{
    line-height: 1;
}

.Statistic{
  width: 10vw;
  max-width: 10rem;
}



/* ----- ADOPSI TEKNOLOGI ----- */
.at-header{
    align-items: center;
}
.list-at{
    margin-left: auto;
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* ----- LEVEL UMKM ----- */
.flex-at{
  width: 100%; 
  height: 100%;
}
.lu-header{
    align-items: center;
}
.list-lu{
    margin-left: auto;
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}






.popup-maps{
  width: 110%;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
}
.nama_provinsi{
  text-transform: capitalize !important;
}
.gender-total{
  width: 100%;
  position: relative;
  border: 2px solid #000;
  border-left: none !important;
  border-right: none !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gender-total:before{
  content: ''; /* Menambahkan elemen pseudo-content */
  display: block;
  position: absolute;
  top: 0; /* Menempatkan elemen di tengah vertikal */
  left: 44%;
  width: 50%;
  height: 100%;
  border-left: 2px solid #000; /* Warna dan ketebalan border bagian atas */
  box-sizing: border-box;
}
.gt-laki{
  width: max-content;
  line-height: 1;
}






/* ----------------------------- GRID LIST UMKM ----------------------------- */

.grid-list-main{
  display: grid;
  grid-template-columns: 30% 33.5% 33.5%;
  grid-template-areas: 
  "mainTu mainMaps mainMaps"
  "mainSu mainMaps mainMaps"
  "mainSu mainAt mainLu"
  "mainSu mainAt mainLu";
  gap: 1rem;
}
.mainTu{ grid-area: mainTu; }
.mainMaps{ grid-area: mainMaps; }
.mainSu{ grid-area: mainSu; }
.mainAt{ grid-area: mainAt; }
.mainLu{ grid-area: mainLu; }

/* -------------------------------------------------------------------------- */
/*                                 RESPONSIVE                                 */
/* -------------------------------------------------------------------------- */

@media only screen and (max-width: 768px) {
  .content-left {
    height: max-content;
  }
  .mainTu{
    width: max-content;
    margin: 0 auto;
  }
  .grid-list-main{
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-template-areas: 
    "mainTu mainTu mainTu"
    "mainMaps mainMaps mainMaps"
    "mainSu mainAt mainLu"
    "mainSu mainAt mainLu";
    gap: 1rem;
  }
}
/* @media only screen and (min-width: 768px) {
  #skala-usaha{
    flex: 5 5;
  }
} */


