/* src/components/Loading.css */
.loading {
  /* height: 90vh !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
  }
  
  .loading-spinner {
    border: 25px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  